<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <ImageShare :btn-title="`分享详情`" :dialog-title="`分成详情分享图`"
                            :share-content-id="`shareContent`"
                            @dialogImageVisible="dialogImageVisible" style="float: right"/>
                <div :style="showBtn?{width:'100%'}:{width:'1000px',margin:'0 auto'}" id="shareContent"
                >
                    <div  v-show="!showBtn">&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div style="margin: 10px auto;font-weight: bold;text-align: center;font-size: 20px"
                         v-show="!showBtn">
                        红人在支付宝自行代开发票或自己开公司的，开票金额都是：应付款合计
                    </div>
                    <h1>分成详情</h1>
                    <el-main>
                        <div class="default-table">
                            <el-table :border="true"
                                      :data="tableData"
                                      :header-cell-style="headClass"
                                      :style="showBtn?{width:'100%'}:{width:'1000px',margin:'0 auto'}"
                                      class="mintable"
                                      style="width: 100%"
                                      tooltip-effect="dark"
                            >
                                <el-table-column
                                        align="center"
                                        label="月份"
                                        prop="date_time"
                                >
                                </el-table-column>
                                <el-table-column
                                        align="center"
                                        label="红人昵称"
                                        prop="nickname"

                                >
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        label="推广应付款"
                                        prop="publicity_pay"

                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.publicity_pay) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        label="电商应付款"
                                        prop="commerce_pay"

                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.commerce_pay) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        label="佣金应付款"
                                        prop="brokerage_pay"

                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.brokerage_pay) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        :width="showBtn?'':'120px'"
                                        align="right"
                                        label="其他应扣/补款"
                                        prop="other_pay"

                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.other_pay) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        label="应付款合计"
                                        prop="due_total"
                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.due_total) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        :width="showBtn?'':'80px'"
                                        align="right"
                                        label="个税"
                                        prop="person_tax"

                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.person_tax) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        label="实付款"
                                        prop="actual_pay"

                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.actual_pay) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        align="center"
                                        label="扣（补款）备注"
                                        prop="deduct_remark"
                                        min-width="120"
                                >
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="minfrom">
                            <el-form :model="tableData[0]" class="form" size="mini">
                                <el-form-item label="红人昵称：">
                                    {{tableData[0].nickname}}
                                </el-form-item>
                                <el-form-item label="月份：">
                                    {{tableData[0].date_time}}
                                </el-form-item>
                                <el-form-item label="推广应付额">
                                    {{tableData[0].publicity_pay}}
                                </el-form-item>
                                <el-form-item label="电商应付款">
                                    {{tableData[0].commerce_pay}}
                                </el-form-item>
                                <el-form-item label="佣金应付款">
                                    {{tableData[0].brokerage_pay}}
                                </el-form-item>
                                <el-form-item label="其他应扣/补款">
                                    {{tableData[0].brokerage_pay}}
                                </el-form-item>
                                <el-form-item label="应付款合计">
                                    {{tableData[0].due_total}}
                                </el-form-item>
                                <el-form-item label="个税">
                                    {{tableData[0].person_tax}}
                                </el-form-item>
                                <el-form-item label="实付款">
                                    {{tableData[0].actual_pay}}
                                </el-form-item>
                                <el-form-item label="扣（补款）备注">
                                    {{tableData[0].deduct_remark}}
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="remarks_button" v-show="showBtn">
                            注：红人在支付宝自行代开发票或自己开公司的，开票金额都是：<span>应付款合计  </span>
                            <div class="item_button">
                                <el-button @click="drawer = true" size="medium"
                                           type="primary"
                                           v-if="userPermissions.indexOf('artist_divide_save') !== -1&&(tableData[0].audit===1||tableData[0].audit===3)">
                                    修改
                                </el-button>
                            </div>
                        </div>
                    </el-main>
                    <el-divider></el-divider>
                    <h1>订单明细</h1>
                    <el-main>
                        <div class="default-table">
                            <el-table
                                    :border="true"
                                    :data="detaile"
                                    :header-cell-style="headClass"
                                    :style="showBtn?{width:'100%'}:{width:'600px'}"
                                    :sum-text="showBtn?'合计':'x'"
                                    :summary-method="getSummaries"

                                    @selection-change="handleSelectionChange"
                                    show-summary
                                    tooltip-effect="dark"
                            >
                                <el-table-column
                                        align="center"
                                        type="selection"
                                        width="55">
                                </el-table-column>
                                <el-table-column
                                        align="center"
                                        label="红人昵称"
                                        prop="nickname"
                                >
                                </el-table-column>
                                <el-table-column
                                        align="center"
                                        label="执行时间"
                                        prop="exec_date"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        align="center"
                                        label="品牌"
                                        prop="brand"
                                >
                                </el-table-column>
                                <el-table-column
                                        align="center"
                                        label="产品"
                                        prop="product"
                                >
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        label="执行价"
                                        min-width="120"
                                        prop="exec_price"
                                >
                                    <template slot-scope={row}>
                                        <span>{{ moneyFormat(row.exec_price) }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="remarks_button">
                            <div class="item_button" v-if="userPermissions.indexOf('artist_divide_affirm') !== -1"
                                 v-show="showBtn">
                                <el-button @click="handleaudit('3')" size="medium" type="success"
                                           v-if="tableData[0].audit===1"> 确认
                                </el-button>
                                <el-button @click="noticeClick()" size="medium"
                                           style="background-color: #409EFF;border: 0"
                                           type="success"
                                           v-if="statusDivideBtn">
                                    <i class="el-icon-chat-line-square"></i> 通知
                                </el-button>
                            </div>

                            <el-row v-if="userPermissions.indexOf('artist_order_delete') !== -1&&showBtn">
                                <el-col :span="11">
                                    <el-button @click="delOrder" size="medium" style="float:left;" type="primary"
                                               v-if="statusDivideBtn">删除明细
                                    </el-button>
                                </el-col>
                                <el-col :span="13">
                                    <el-button @click="handleaudit('5')" size="medium" type="success"
                                               v-if="statusDivideBtn">审核通过
                                    </el-button>
                                    <el-button @click="handleaudit('6')" size="medium" style="te6xt-align: center"
                                               type="danger"
                                               v-if="statusDivideBtn"> 关闭审核
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-main>
                </div>
                <LogList></LogList>
            </div>
        </PageHeaderLayout>
        <el-drawer
                :visible.sync="drawer"
                :with-header="false"
                direction="rtl">
            <el-form :model="tableData[0]" class="form" label-width="120px" ref="articleForm">
                <el-form-item label="红人昵称：">
                    {{tableData[0].nickname}}

                </el-form-item>
                <el-form-item label="月份：">
                    <el-input
                            :disabled="true"
                            v-model="tableData[0].date_time">
                    </el-input>
                </el-form-item>

                <el-form-item label="推广应付额">
                    <el-input :clearable="true" type="number" v-model="tableData[0].publicity_pay"></el-input>
                </el-form-item>
                <el-form-item label="电商应付款">
                    <el-input :clearable="true" type="number" v-model="tableData[0].commerce_pay"></el-input>
                </el-form-item>
                <el-form-item label="佣金应付款">
                    <el-input :clearable="true" type="number" v-model="tableData[0].brokerage_pay"></el-input>
                </el-form-item>
                <el-form-item label="其他应扣/补款">
                    <el-input :clearable="true" type="number" v-model="tableData[0].other_pay"></el-input>
                </el-form-item>
                <el-form-item label="应付款合计">
                    <el-input :clearable="true" type="number" v-model="tableData[0].due_total"></el-input>
                </el-form-item>
                <el-form-item label="个税">
                    <el-input :clearable="true" type="number" v-model="tableData[0].person_tax"></el-input>
                </el-form-item>
                <el-form-item label="实付款">
                    <el-input :clearable="true" type="number" v-model="tableData[0].actual_pay"></el-input>
                </el-form-item>
                <el-form-item label="扣（补款）备注">
                    <el-input v-model="tableData[0].deduct_remark"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="saveUpdata" size="medium" type="success">保存修改</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>

<script>
  import PageHeaderLayout from '@/layouts/PageHeaderLayout'
  import { mapGetters } from 'vuex'
  import DingTalkDeptSelect from '@/pages/departments/components/DingTalkDeptSelect'
  import NotifyTaskList from '@/pages/lb/NotifyTaskList'
  import DingTalkNotify from '@/components/dingTalkNotify/index'
  import LogList from '@/pages/artist_commission/components/LogList'

  export default {
    name: 'PublicationIndex',
    components: { PageHeaderLayout, DingTalkDeptSelect, NotifyTaskList, DingTalkNotify, LogList },
    computed: {
      ...mapGetters(['userPermissions', 'userInfo']),
      statusDivideBtn() {
        let arr = [1, 4, 5, 6]
        let returnValue
        arr.includes(this.tableData[0].audit) ? returnValue = false : returnValue = true
        return returnValue
      }
    },
    data() {
      return {
        tableData: [{}],
        detaile: [],
        selection: [],
        drawer: false,
        isIndeterminate: true,
        checkAll: false,
        checkedMembers: [],
        loadingNotify: false,
        showTaskList: false,
        showBtn: true
      }
    },
    watch: {},
    methods: {
      //批量样式居中
      headClass() {
        return 'text-align:center'
      },

      defaultCheckBox() {
        this.checkAll = false
        this.checkedMembers = []
        this.members = []
        this.isIndeterminate = true
      },
      noticeClick() {
        this.$confirm('是否立即通知财务', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let postData = { id: this.$route.params.id, type: 'finance', rec_user_ids: [this.tableData[0].user_id] }
          let info = await this.$api.dingTalkNotifyartistDivide(postData)
          if (info) {
            this.$notify.success('成功！')
          } else
            this.$notify.error('未知错误')
        }).catch(() => {
          this.$notify.info('已取消通知')
        })
      },

      handleSelectionChange(val) {
        this.selection = val
      },
      delOrder() {
        //删除按钮
        let order_ids = []
        this.selection.forEach((item) => {
          order_ids.push(item.id)
        })
        if (order_ids.length != 0) {
          this.$confirm('确认删除所选订单', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            let info = await this.$api.delOrder(order_ids)
            if (info) {
              this.$notify.success('删除成功')
              this.getList()
            } else {
              this.$notify.error('未知错误')
            }
          }).catch(() => {
            this.$notify.info('已取消')
          })
        } else this.$message.error('请选择要删除的订单')
      },
      saveUpdata() {
        this.$refs['articleForm'].validate((valid) => {
          if (valid) {
            this.formSubmit()
          } else {
            this.$message.error('数据验证失败，请检查必填项数据！')
          }
        })
        return true
      },
      // 修改保存处理
      async formSubmit() {
        let isdata = this.tableData[0]
        let fromData = {
          'id': isdata.id, //分成id
          'date_time': isdata.date_time, //月份
          'nickname': isdata.nickname, //红人昵称
          'publicity_pay': isdata.publicity_pay, //推广应付款
          'commerce_pay': isdata.commerce_pay, //电商应付款
          'brokerage_pay': isdata.brokerage_pay, //佣金应付款
          'other_pay': isdata.other_pay, //其他应扣/补款
          'due_total': isdata.due_total, //应付款合计
          'person_tax': isdata.person_tax, //个税
          'actual_pay': isdata.actual_pay, //实付款
          'deduct_remark': isdata.deduct_remark //扣（补款）备注
        }
        let id = await this.$api.updateArtistDivide(fromData)
        if (id) {
          this.$notify.success('保存成功！')
          this.getList()
          this.drawer = false

        } else this.$notify.error('数据不可为空或有其他失败')
      },
      handleaudit(id) {
        let msg = id === '6' ? '是否关闭该审核' : '是否确认分成数据'
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = { 'id': this.$route.params.id, 'audit': id }
          this.auditSubmit(data)
        }).catch(() => {
          this.$notify.info('已取消')
        })
      },
      async auditSubmit(data) {
        let id = await this.$api.artistAudit(data)
        if (id) {
          if (data.audit == 3) {
            this.noticeClick()
          }
          this.$notify.success('审核成功！')
          this.getList()
        } else this.$notify.error('未知失败')
      },
      moneyFormat(value) {
        return this.$utils.numberFormat(value, 2, '.', ',', 'round')
      },
      async getList() {
        let { divide_detail, order_detail } = await this.$api.getArtistDivide(this.$route.params.id)
        this.$nextTick(() => {
          this.tableData = [divide_detail]
          this.detaile = order_detail
        })
      },
      dialogImageVisible(val) {
        this.showBtn = val
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        if (data && data.length > 0) {
          sums.push('合计')
          let actual_pay = 0
          data.forEach(({ exec_price }) => {
              actual_pay += Number(exec_price)
            }
          )
          for (let i = 1; i <= columns.length - 1; i++) {
            if (i === 5) {
              sums.push(this.moneyFormat(actual_pay))
            } else sums.push('')
          }
        }
        return sums
      }


    }
    ,
    created() {
      this.getList()
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .el-table .el-table__footer-wrapper .cell {
        font-weight: bold !important;
        font-size: 14px !important;
        color: #000;
    }

    h1 {
        /*text-align: center;*/
        margin-left: 20px
    }

    .hide {
        display: none;
    }

    .el-main {
        min-height: auto;
    }

    .remarks_button {
        margin: 20px 0;

        > span {
            color: red;
        }

        .item_button {
            margin-right: 10px;
            margin-top: 6px;
            text-align: right;
        }
    }

    .form {
        width: 500px;
        margin: 10% auto;
    }

    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    ::v-deep input[type=‘number’] {
        -moz-appearance: textfield !important;
    }

    .dept-member {
        width: 90%;
        border: #DCDFE6 1px solid;
        min-height: 500px;
        margin: 0 auto;
    }

    .notify-dept {
        width: 500px;
        border: #DCDFE6 1px solid;
        padding: 10px;
        /*max-height: 300px;*/
        overflow-y: scroll;
    }

    .all_box {
        margin-left: 90px;
        /*margin-top: 20px;*/
        float: left;

    }

    .check-all {
        height: 20px;
        padding: 5px;
        margin-bottom: 5px;
    }

    .member-list {
        > div > label {
            display: block;
            width: 180px;
            margin: 10px;
            padding: 0;

        }
    }

    .button_box {
        text-align: center;

        > .notify-record {
            margin-top: 10px;
            padding: 10px;
            max-height: 200px;
            overflow-y: scroll;
        }

    }

    .minfrom {
        > .form {
            width: auto;
        }

        display: none;
    }

    .opt-log {
        max-width: 800px;
        height: 300px;
        overflow: scroll;
        border: 1px solid #EBEEF5;
        border-radius: 10px;
        padding: 10px;
    }

    @media screen and (max-width: 600px) {

        .minfrom {
            display: block;
            margin-left: 10px;

            > form {
                margin: 0;

                > .el-form-item {
                    line-height: 10px !important;
                    margin: 4px auto;
                    overflow: scroll;
                }

            }
        }
        .mintable {
            display: none;
        }

    }

</style>
